/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StoreHomeTimeline_Books_query = {
    readonly store: {
        readonly id: string;
        readonly data: {
            readonly books: {
                readonly edges: ReadonlyArray<{
                    readonly node: {
                        readonly id: string;
                        readonly __typename: string;
                        readonly userId: string;
                        readonly bookId: string;
                        readonly bookKey: {
                            readonly bookId: string;
                            readonly userId: string;
                        };
                        readonly timeAdded: number;
                        readonly store: {
                            readonly id: string;
                            readonly slug: string;
                            readonly name: string;
                            readonly data: {
                                readonly __typename: string;
                                readonly links: {
                                    readonly twitter: {
                                        readonly username: string;
                                    } | null;
                                };
                            } | null;
                        };
                        readonly rating: API$BookRating;
                        readonly description: string;
                        readonly private: {
                            readonly identification: {
                                readonly titleAndAuthor: string;
                            };
                        } | null;
                        readonly data: {
                            readonly __typename: "BookDataPending";
                            readonly sectionId: string;
                        } | {
                            readonly __typename: "BookDataRejected";
                            readonly timeWhenRejected: number;
                            readonly sectionId: string;
                            readonly reason: string;
                        } | {
                            readonly __typename: "BookDataDeleted";
                            readonly info: {
                                readonly slug: string;
                                readonly cover: {
                                    readonly url: string;
                                    readonly aspectRatio: number;
                                };
                                readonly title: string;
                                readonly subtitle: string;
                                readonly authors: ReadonlyArray<string>;
                            };
                        } | {
                            readonly __typename: "BookDataLive";
                            readonly sectionId: string;
                            readonly info: {
                                readonly slug: string;
                                readonly title: string;
                                readonly subtitle: string;
                                readonly authors: ReadonlyArray<string>;
                                readonly cover: {
                                    readonly url: string;
                                    readonly aspectRatio: number;
                                };
                                readonly amazonURL: string;
                                readonly purchasingDetails: {
                                    readonly variants: {
                                        readonly paperback: {
                                            readonly price: number;
                                        } | null;
                                        readonly hardcover: {
                                            readonly price: number;
                                        } | null;
                                    };
                                    readonly shippingCost: {
                                        readonly freePriority: number;
                                    };
                                } | null;
                            };
                            readonly likes: {
                                readonly id: string;
                                readonly aggregate: {
                                    readonly id: string;
                                    readonly likeCount: number;
                                };
                                readonly relativeToStore: {
                                    readonly isLiked: boolean;
                                } | null;
                            };
                            readonly comments: {
                                readonly id: string;
                                readonly count: number;
                            };
                            readonly review: {
                                readonly id: string;
                                readonly postId: string;
                                readonly createdTime: number;
                                readonly postData: {
                                    readonly lastEditedTime: number;
                                    readonly stage: {
                                        readonly __typename: "ReviewStagePublished";
                                        readonly publishedTime: number;
                                    } | {
                                        /*This will never be '%other', but we need some
                                        value in case none of the concrete values match.*/
                                        readonly __typename: "%other";
                                    };
                                    readonly snippet: string;
                                } | null;
                            } | null;
                        } | {
                            /*This will never be '%other', but we need some
                            value in case none of the concrete values match.*/
                            readonly __typename: "%other";
                        };
                    };
                }>;
            };
        } | null;
    } | null;
    readonly " $refType": "StoreHomeTimeline_Books_query";
};
export type StoreHomeTimeline_Books_query$data = StoreHomeTimeline_Books_query;
export type StoreHomeTimeline_Books_query$key = {
    readonly " $data"?: StoreHomeTimeline_Books_query$data;
    readonly " $fragmentRefs": FragmentRefs<"StoreHomeTimeline_Books_query">;
};



const node: ReaderFragment = (function(){
var v0 = [
  "store",
  "data",
  "books"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bookId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sectionId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "BookCover",
  "kind": "LinkedField",
  "name": "cover",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "aspectRatio",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subtitle",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "authors",
  "storageKey": null
},
v11 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "price",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "booksStartCursor"
    },
    {
      "kind": "RootArgument",
      "name": "pageSize"
    },
    {
      "kind": "RootArgument",
      "name": "storeSlug"
    },
    {
      "kind": "RootArgument",
      "name": "userId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "pageSize",
        "cursor": "booksStartCursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "pageSize",
          "cursor": "booksStartCursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./StoreHomeTimeline_Books_Store_Query.graphql')
    }
  },
  "name": "StoreHomeTimeline_Books_query",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "slug",
          "variableName": "storeSlug"
        }
      ],
      "concreteType": "Store",
      "kind": "LinkedField",
      "name": "store",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "StoreData",
          "kind": "LinkedField",
          "name": "data",
          "plural": false,
          "selections": [
            {
              "alias": "books",
              "args": null,
              "concreteType": "BooksConnection",
              "kind": "LinkedField",
              "name": "__StoreHomeTimeline_books_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BooksConnectionEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Book",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v2/*: any*/),
                        (v3/*: any*/),
                        (v4/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "BookKey",
                          "kind": "LinkedField",
                          "name": "bookKey",
                          "plural": false,
                          "selections": [
                            (v4/*: any*/),
                            (v3/*: any*/)
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "timeAdded",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Store",
                          "kind": "LinkedField",
                          "name": "store",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            (v5/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "name",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "StoreData",
                              "kind": "LinkedField",
                              "name": "data",
                              "plural": false,
                              "selections": [
                                (v2/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "StoreLinks",
                                  "kind": "LinkedField",
                                  "name": "links",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "StoreLinksTwitter",
                                      "kind": "LinkedField",
                                      "name": "twitter",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "username",
                                          "storageKey": null
                                        }
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "rating",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "description",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Book_Private",
                          "kind": "LinkedField",
                          "name": "private",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "BookIdentification",
                              "kind": "LinkedField",
                              "name": "identification",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "titleAndAuthor",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": null,
                          "kind": "LinkedField",
                          "name": "data",
                          "plural": false,
                          "selections": [
                            (v2/*: any*/),
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v6/*: any*/)
                              ],
                              "type": "BookDataPending",
                              "abstractKey": null
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "timeWhenRejected",
                                  "storageKey": null
                                },
                                (v6/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "reason",
                                  "storageKey": null
                                }
                              ],
                              "type": "BookDataRejected",
                              "abstractKey": null
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "CatalogBook",
                                  "kind": "LinkedField",
                                  "name": "info",
                                  "plural": false,
                                  "selections": [
                                    (v5/*: any*/),
                                    (v7/*: any*/),
                                    (v8/*: any*/),
                                    (v9/*: any*/),
                                    (v10/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "type": "BookDataDeleted",
                              "abstractKey": null
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v6/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "CatalogBook",
                                  "kind": "LinkedField",
                                  "name": "info",
                                  "plural": false,
                                  "selections": [
                                    (v5/*: any*/),
                                    (v8/*: any*/),
                                    (v9/*: any*/),
                                    (v10/*: any*/),
                                    (v7/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "amazonURL",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "BookPurchasingDetails",
                                      "kind": "LinkedField",
                                      "name": "purchasingDetails",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "alias": null,
                                          "args": null,
                                          "concreteType": "BookVariants",
                                          "kind": "LinkedField",
                                          "name": "variants",
                                          "plural": false,
                                          "selections": [
                                            {
                                              "alias": null,
                                              "args": null,
                                              "concreteType": "BookVariant",
                                              "kind": "LinkedField",
                                              "name": "paperback",
                                              "plural": false,
                                              "selections": (v11/*: any*/),
                                              "storageKey": null
                                            },
                                            {
                                              "alias": null,
                                              "args": null,
                                              "concreteType": "BookVariant",
                                              "kind": "LinkedField",
                                              "name": "hardcover",
                                              "plural": false,
                                              "selections": (v11/*: any*/),
                                              "storageKey": null
                                            }
                                          ],
                                          "storageKey": null
                                        },
                                        {
                                          "alias": null,
                                          "args": null,
                                          "concreteType": "BookShippingCost",
                                          "kind": "LinkedField",
                                          "name": "shippingCost",
                                          "plural": false,
                                          "selections": [
                                            {
                                              "alias": null,
                                              "args": null,
                                              "kind": "ScalarField",
                                              "name": "freePriority",
                                              "storageKey": null
                                            }
                                          ],
                                          "storageKey": null
                                        }
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "BookLikes",
                                  "kind": "LinkedField",
                                  "name": "likes",
                                  "plural": false,
                                  "selections": [
                                    (v1/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "BookLikesAggregate",
                                      "kind": "LinkedField",
                                      "name": "aggregate",
                                      "plural": false,
                                      "selections": [
                                        (v1/*: any*/),
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "likeCount",
                                          "storageKey": null
                                        }
                                      ],
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": [
                                        {
                                          "kind": "Variable",
                                          "name": "userId",
                                          "variableName": "userId"
                                        }
                                      ],
                                      "concreteType": "BookLikesRelativeToStore",
                                      "kind": "LinkedField",
                                      "name": "relativeToStore",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "isLiked",
                                          "storageKey": null
                                        }
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Comments",
                                  "kind": "LinkedField",
                                  "name": "comments",
                                  "plural": false,
                                  "selections": [
                                    (v1/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "count",
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Review",
                                  "kind": "LinkedField",
                                  "name": "review",
                                  "plural": false,
                                  "selections": [
                                    (v1/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "postId",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "createdTime",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": "postData",
                                      "args": null,
                                      "concreteType": "ReviewData",
                                      "kind": "LinkedField",
                                      "name": "data",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "lastEditedTime",
                                          "storageKey": null
                                        },
                                        {
                                          "alias": null,
                                          "args": null,
                                          "concreteType": null,
                                          "kind": "LinkedField",
                                          "name": "stage",
                                          "plural": false,
                                          "selections": [
                                            (v2/*: any*/),
                                            {
                                              "kind": "InlineFragment",
                                              "selections": [
                                                {
                                                  "alias": null,
                                                  "args": null,
                                                  "kind": "ScalarField",
                                                  "name": "publishedTime",
                                                  "storageKey": null
                                                }
                                              ],
                                              "type": "ReviewStagePublished",
                                              "abstractKey": null
                                            }
                                          ],
                                          "storageKey": null
                                        },
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "snippet",
                                          "storageKey": null
                                        }
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "type": "BookDataLive",
                              "abstractKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endCursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
(node as any).hash = '92d8a7fc976cabf5f83b903c6744569f';
export default node;
