import { assert, noCase } from '@indieocean/utils'
import React from 'react'
import { Cover } from '../../../../Common/Cover'
import { AppLink } from '../../../../Common/Tools/AppLink'
import { useUpdateCacheForBook } from '../../../Common/UseUpdateCacheForBook'
import { BookUtils } from '../../Book/BookUtils'
import { BookActionBar } from '../../Book/Common/BookActionBar'
import { BookReviewSnippet } from '../../Book/Common/BookReviewSnippet'
import { BookVisibleInAdmin, isBookAdmin, isBookLive } from '../../Book/Home/Book'
import { BookRating } from '../../BookRating'
import { StoreHomeItem } from './StoreHomeItem'

export const StoreHomeBook = React.memo(
  ({
    book,
    className = '',
    inlineLink = false,
    background,
    index,
  }: {
    book: BookVisibleInAdmin
    className?: string
    inlineLink?: boolean
    background: 'page' | 'extraLightSection'
    index: number
  }) => {
    const {bookId} = book
    useUpdateCacheForBook(book)

    const [path, cover, title, subtitle, authors, purchasingDetails] = (() => {
      switch (book.data.__typename) {
        case 'BookDataPending':
        case 'BookDataRejected':
          assert(isBookAdmin(book))
          return [
            null,
            null,
            book.private.identification.titleAndAuthor,
            '',
            null,
            null,
          ] as const
        case 'BookDataLive':
          assert(isBookLive(book))
          return [
            inlineLink
              ? BookUtils.inlinePathIfLive(book)
              : BookUtils.path(book).root(),
            book.data.info.cover,
            book.data.info.title,
            book.data.info.subtitle,
            book.data.info.authors,
            book.data.info.purchasingDetails,
          ] as const
        default:
          noCase(book.data)
      }
    })()

    return (
      <StoreHomeItem
        className={`${className}`}
        id={`book-${bookId}`}
        index={index}
      >
        <div className="flex flex-col justify-start items-center  flex-shrink-0 ">
          <Cover
            cover={cover ?? undefined}
            size="w-[45px]"
            href={path ?? undefined}
          />
        </div>
        <div className="relative">
          <_Status type={book.data.__typename} />
          <AppLink className="block font-medium" path={path}>
            {title}
          </AppLink>
          {subtitle.length > 0 && (
            <AppLink className=" block" path={path}>
              {subtitle}
            </AppLink>
          )}
          {authors && (
            <AppLink className="block font-medium" path={path}>
              {authors.join(', ')}
            </AppLink>
          )}
          <BookRating className=" text-sm" rating={book.rating} />
          <BookReviewSnippet
            book={book}
            pathIfQuickReview={path}
            background={background}
          />
          <BookActionBar
            book={book}
            className="mt-2"
            backAfterDelete={false}
            showBuy
            onScrollToComments={null}
          />
        </div>
      </StoreHomeItem>
    )
  }
)

const _Status = React.memo(
  ({type}: {type: BookVisibleInAdmin['data']['__typename']}) => {
    if (type === 'BookDataLive') return <></>
    const helper = (className: string, label: string) => (
      <div
        className={
          'pl-2 pr-2 float-right pt-1 pb-1 text-sm text-center rounded-full w-28 text-whiteFG whitespace-nowrap ' +
          className
        }
      >
        {label}
      </div>
    )
    return type === 'BookDataPending'
      ? helper('bg-pendingBG', 'pending')
      : type === 'BookDataRejected'
      ? helper('bg-rejectedBG', 'not available')
      : noCase(type)
  }
)
