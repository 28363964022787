import React from 'react'
import {StoreHomeBooks} from '../src/Components/Pages/Store/StoreHome/Books/StoreHomeBooks'
import { StoreHomeTimeline } from '../src/Components/Pages/Store/StoreHome/Timeline/StoreHomeTimeline'
import {useStoreSlugURLParam} from '../src/Components/Pages/Store/UseStoreSlugURLParam'

export default React.memo(() => {
  const storeSlug = useStoreSlugURLParam()
  // Need key to reset between stores, especially between self-store and not.
  return <StoreHomeTimeline key={storeSlug} />
})
