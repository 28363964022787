import { fGet, noCase } from '@indieocean/utils'
import React, { useEffect } from 'react'
import { useRelayEnvironment } from 'react-relay'
import { commitLocalUpdate } from 'relay-runtime'
import { useAssertConst } from '../../Utils/UseAssertConst'
import { Book, BookLive } from '../Store/Book/Home/Book'

export function useUpdateCacheForBook(book: Book) {
  const gqlStoreId = book.store.id
  const slug = (() => {
    switch (book.data.__typename) {
      case 'BookDataPending':
      case 'BookDataWithdrawn':
      case 'BookDataRejected':
        return null
      case 'BookDataLive':
      case 'BookDataDeleted':
        return book.data.info.slug
      default:
        noCase(book.data)
    }
  })()
  const {bookId} = book

  const relayEnv = useRelayEnvironment()
  useEffect(() => {
    commitLocalUpdate(relayEnv, cache => {
      const storeRecord = fGet(cache.get(gqlStoreId))
      const dataRecord = fGet(storeRecord.getLinkedRecord('data'))
      const bookRecord = fGet(cache.get(book.id))
      slug && dataRecord.setLinkedRecord(bookRecord, 'book', {slug})
      dataRecord.setLinkedRecord(bookRecord, 'book', {bookId})
    })
  }, [gqlStoreId, book.id, relayEnv, slug, bookId])
  useAssertConst([gqlStoreId, book.id, relayEnv, bookId])
}

