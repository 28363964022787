import {API} from '@indieocean/apidef'
import {fGet, noCase} from '@indieocean/utils'
import _ from 'lodash'
import Head from 'next/head'
import React, {useMemo} from 'react'
import {useLazyLoadQuery, usePaginationFragment} from 'react-relay'
import {graphql} from 'relay-runtime'
import {GQL} from '../../../../../Generated/GQL/TypesFromSDL'
import StorePage from '../../../../Common/Page/StorePage'
import {useBasicStoreLive} from '../../../../Common/Page/WithBasicStoreLive'
import {CenteredCircleLoadTrigger} from '../../../../Common/Tools/CenteredCircleLoadTrigger'
import {assertStateMatch} from '../../../../Common/WithRelay'
import {useUser} from '../../../../Common/WithUser'
import {useIsOwnStore} from '../../../Common/UseIsOwnStore'
import {isPublishedPost, PublishedPost} from '../../../Post/Post'
import {
  BookVisibleInAdmin,
  isBookLive,
  isBookVisibleInAdmin,
} from '../../Book/Home/Book'
import {useStoreSlugURLParam} from '../../UseStoreSlugURLParam'
import {StoreHomeBase} from '../Base/StoreHomeBase'
import {StoreHomeBlogPost} from '../Common/StoreHomeBlogPost'
import {StoreHomeBook} from '../Common/StoreHomeBook'
import {StoreHomeReview} from '../Common/StoreHomeReview'
import {StoreHomeShortPost} from '../Common/StoreHomeShortPost'
import {AddTimestamp} from '../../../Common/AddTimestamp'
import {storeHomePostsfragment} from '../Posts/StoreHomePosts'
import {
  StoreHomePosts_query,
  StoreHomePosts_query$key,
} from '../Posts/__generated__/StoreHomePosts_query.graphql'
import {StoreHomeTimelineQuery} from './__generated__/StoreHomeTimelineQuery.graphql'
import {
  StoreHomeTimeline_Books_query,
  StoreHomeTimeline_Books_query$key,
} from './__generated__/StoreHomeTimeline_Books_query.graphql'

const pageSize = 20
const query = graphql`
  query StoreHomeTimelineQuery(
    $storeSlug: String!
    $userId: String
    $pageSize: Int!
    $booksStartCursor: String!
    $postsStartCursor: String!
    $drafts: Boolean!
  ) {
    ...StorePage_query
    ...StoreHomeTimeline_Books_query
    ...StoreHomePosts_query
  }
`

export const StoreHomeTimeline = React.memo(() => {
  const storeSlug = useStoreSlugURLParam()
  const user = useUser()
  const data = useLazyLoadQuery<StoreHomeTimelineQuery>(query, {
    storeSlug,
    pageSize,
    booksStartCursor: API.GQLDBCursor.start,
    postsStartCursor: API.GQLTimeCursor.start(),
    userId: user?.userId,
    drafts: false,
  })

  return (
    <StorePage queryDataKey={data} header={{type: 'store', size: 'grid3'}}>
      <StoreHomeBase currentTab="timeline">
        <_AfterData booksDataKey={data} postsDataKey={data} />
      </StoreHomeBase>
    </StorePage>
  )
})

const booksfragment = graphql`
  fragment StoreHomeTimeline_Books_query on Query
  @refetchable(queryName: "StoreHomeTimeline_Books_Store_Query") {
    store(slug: $storeSlug) {
      id
      data {
        books(first: $pageSize, after: $booksStartCursor)
          @connection(key: "StoreHomeTimeline_books") {
          edges {
            node {
              id
              ...Book_book @relay(mask: false)
            }
          }
        }
      }
    }
  }
`

function _paginationHandler(
  booksResult: {
    hasNext: boolean
    loadNext: (pageSize: number) => void
    data: StoreHomeTimeline_Books_query
  },
  postsResult: {
    hasNext: boolean
    loadNext: (pageSize: number) => void
    data: StoreHomePosts_query
  }
) {
  const booksData = booksResult.data as any as GQL.StoreHomeTimeline_Books_Query
  const postsData = postsResult.data as any as GQL.StoreHomePosts_Query

  const isOwnStore = useIsOwnStore(useStoreSlugURLParam())
  assertStateMatch(
    booksData.store &&
      booksData.store.data &&
      postsData.store &&
      postsData.store.data
  )

  const hasNext = booksResult.hasNext || postsResult.hasNext
  const loadNext = () => {
    if (booksResult.hasNext) booksResult.loadNext(pageSize)
    if (postsResult.hasNext) postsResult.loadNext(pageSize)
  }
  const data = useMemo(() => {
    // Filter here, because even though the server won't return it
    // directly, local cache updating can set it to bad states.
    const books = fGet(booksData.store?.data)
      .books.edges.map(x => x.node)
      .filter((x): x is BookVisibleInAdmin =>
        isOwnStore ? isBookVisibleInAdmin(x) : isBookLive(x)
      )
    const posts = fGet(postsData.store?.data)
      .posts.edges.map(x => x.node)
      .filter((x): x is PublishedPost =>
        isPublishedPost(x) && x.__typename === 'Review'
          ? isBookLive(x.postData.book)
          : true
      )

    return _.sortBy([...books, ...posts], x => {
      switch (x.__typename) {
        case 'BlogPost':
        case 'Review':
        case 'ShortPost':
          return -x.postData.stage.publishedTime
        case 'Book':
          return -x.timeAdded
        default:
          noCase(x)
      }
    })
  }, [booksData, postsData, isOwnStore])
  return {hasNext, loadNext, data}
}

const _AfterData = React.memo(
  ({
    booksDataKey,
    postsDataKey,
  }: {
    booksDataKey: StoreHomeTimeline_Books_query$key
    postsDataKey: StoreHomePosts_query$key
  }) => {
    const books = usePaginationFragment(booksfragment, booksDataKey)
    const posts = usePaginationFragment(storeHomePostsfragment, postsDataKey)

    const {data, hasNext, loadNext} = _paginationHandler(books, posts)
    const store = useBasicStoreLive()

    return (
      <div className="flex flex-col gap-y-16 mt-10 mb-10 relative z-10">
        <Head>
          <title>Timeline of {store.name} on IndieOcean</title>
        </Head>
        {data.length === 0 && !hasNext && (
          <h2 className="text-center lighten-2">Nothing here yet.</h2>
        )}
        {data.map((x, i) => {
          const props = {key: x.id, singleColumn: false, index: i}
          switch (x.__typename) {
            case 'BlogPost':
              return <StoreHomeBlogPost post={x} {...props} />
            case 'ShortPost':
              return <StoreHomeShortPost post={x} {...props} />
            case 'Review':
              return <StoreHomeReview review={x} {...props} />
            case 'Book':
              return (
                <AddTimestamp key={x.id} time={x.timeAdded}>
                  <StoreHomeBook book={x} background="page" index={i} />
                </AddTimestamp>
              )
            default:
              noCase(x)
          }
        })}
        {hasNext && (
          <div className="relative w-full h-20">
            <CenteredCircleLoadTrigger onTrigger={loadNext} />
          </div>
        )}
      </div>
    )
  }
)
